import './App.css';
import logo from './assets/logo_color_nobg.svg';

function App() {
  return (
    <div className="App">
      <div className="pathUp__header"> 
        <img src={ logo } alt="PathUp logo"/>
      </div>
      <div className="pathUp__mainContent">
        <h2>Coming Soon ...</h2>
      </div>
      <div className="pathUp__contact">
      <p>To contact us with your questions or requests, please send an email to <a href="mailto:support@pathup.ca?">support@pathup.ca</a></p>
      </div>
    </div>
  );
}

export default App;
